import styled from "styled-components";
import { Form as Unform } from "@unform/web";

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  width: 100%;

  input,
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    height: 40px;
    padding: 8px 18px;
    border-color: #eee;
    font-size: 14px;
    display: block;
    width: 100%;
    border: 1px solid #ced4da;
    margin-bottom: 20px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
    margin-bottom: 20px;
  }
`;

export const Error = styled.span`
  color: red;
  margin-bottom: 24px;
`;
