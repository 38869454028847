import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  color: #222;
  margin-bottom: 40px;

  @media (max-width: 599px) {
    font-size: 16px;
  }
`;
